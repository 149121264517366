<template>
  <div id="app">
    <div class="top-header flex-between flex-ai-center">
      <div class="flex-center">
        <img width="34" src="./assets/logo@2x.png" alt="logo" />
        <span class="title">篝火后台管理中心</span>
      </div>
      <div style="padding-right:20px;" class="flex-row" v-if="$route.path !== '/login'">
        <div style="margin-right:20px;">欢迎您，{{userInfo.name}}</div>
        <div class="pointer" style="margin-right:20px;color:#3399FF;" @click="dialogVisible = true">修改密码</div>
        <div class="pointer" @click="_logout" style="color:#CC0099;">退出</div>
      </div>
    </div>
    <router-view/>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="360px" :before-close="_closeDialog">
      <el-form :model="formData" label-position="left" label-width="auto">
          <el-form-item label="密码：" required>
            <el-input type="text" placeholder="请输入登录密码" v-model="formData.password" clearable></el-input>
          </el-form-item>
      </el-form>
      <el-button type="primary" @click="_onSubmitData">保存</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { storage } from '@/storage'
import registerRoutes from '@/router/routing'
import { updatePwd, platformUpdatePwd } from '@/util/api'
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      formData: {
        password: '',
      },
      dialogVisible: false // 修改密码的弹框
    } 
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ])
  },
  mounted () {
    console.log('---App mounted:', this.userInfo)
    this._restoreState()
  },
  methods: {
    ...mapActions([
      'dispatchUser'
    ]),
    async _restoreState () {
      await this.dispatchUser()
      const role = this.userInfo.roleId || 1
      registerRoutes(role, this.$router)
    },
    _closeDialog () {
      this.password = ''
      this.dialogVisible = false
    },
    _onSubmitData () {
      if (!this.formData.password) {
        this.$message.error('请输入密码!')
        return
      }
      if (this.userInfo.type === 2) {
        platformUpdatePwd(this.formData).then(() => {
          this.$message.success('修改成功！请重新登录')
          this._closeDialog()
          this._logout()
        })
      } else {
        updatePwd(this.formData).then(() => {
          this.$message.success('修改成功！请重新登录')
          this._closeDialog()
          this._logout()
        })
      }
    },
    _logout () {
      storage.remove('token')
      this.$router.push('/login')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-color: #272734; */
}
html, body {
  margin: 0;
  padding: 0;
}
.top-header {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  background: #DDDDDD;
}
.title {
  margin-left: 10px;
}
</style>
