<template>
  <div class="panel">
    <div class="flex-start flex-jc-center flex-column">
      <div class="flex-start flex-ai-end" style="width:100%;height:44%;margin-bottom:10px;">
        <!-- <span class="item-title">系统类型</span>
        <el-select size="mini" style="width:120px !important;margin-right:25px;" v-model="platform">
          <el-option
            v-for="item in platformList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select> -->
        <!-- <span class="item-title">版本</span>
        <el-select size="mini" style="width:120px !important;" v-model="version">
          <el-option
            v-for="item in versionList"
            :key="item.id"
            :label="item.version"
            :value="item.id">
          </el-option>
        </el-select> -->
        <div v-if="$slots.plugin">
          <slot name="plugin"></slot>
        </div>
      </div>
      <div class="flex-between" style="width:100%;">
        <div>
          <span class="item-title">时区</span>
          <el-select
            size="mini"
            v-model="timeZone"
            filterable
            style="width:160px !important;">
            <!-- <el-option label="全部" value=""></el-option> -->
            <el-option
              v-for="item in zoneList"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="flex-row flex-jc-end">
          <el-button size="mini" class="el-icon-arrow-left" style="margin:0 8px;height:28px;" @click="quickDate(-1)"></el-button>
          <el-date-picker
            size="mini"
            v-model="dateRange"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="quickTime=''">
          </el-date-picker>
          <el-button size="mini" class="el-icon-arrow-right" style="margin:0 8px;height:28px;" @click="quickDate(1)"></el-button>
          <!-- <span style="margin-left:10px;"></span> -->
          <el-select size="mini" v-model="quickTime" style="width:115px !important;margin-right:10px;" @change="shortcutDate" placeholder="快速选择">
            <el-option
              v-for="item in quickTimeList"
              :key="item.id"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button style="width:70px;height:28px;line-height:28px;text-align:center;padding:0;" @click="_query">查询</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { channelConfig } from '../util/api'
import { formatDate } from '../util'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SearchPanel',
  data() {
    return {
      dateRange: [],
      quickTime: '',
      platform: 0,
      version: 0,
      countryCode: '',
      primaryChannel: {},
      subChannel: '',
      timeZone: 8,
      zoneList: [],
      platformList: [],
      versionList: [],
      quickTimeList: [
        {
          id: 1,
          value: 0,
          name: '今日'
        },
        {
          id: 2,
          value: 1,
          name: '昨日'
        },
        {
          id: 3,
          value: 7,
          name: '最近7天'
        },
        {
          id: 4,
          value: 15,
          name: '最近15天'
        },
        {
          id: 5,
          value: 30,
          name: '最近30天'
        }
      ]
    }
  },
  created() {
    // this.dispatchChannelList()
    // this.dispatchCountryList()
    for(let i = 0;i < 10;i++) {
      this.zoneList.push(
        {
          code: i,
          name: `GMT+${i}`
        },
      )
    }
  },
  mounted () {
    // 默认选择今日
    this.quickTime = 0
    this.shortcutDate(0)
  },
  computed: {
    ...mapGetters([
      'channelList',
      'countryList'
    ])
  },
  methods: {
    ...mapActions([
      'dispatchChannelList',
      'dispatchCountryList'
    ]),
    shortcutDate (offset) {
      const end = new Date()
      const start = new Date()
      start.setHours(0, 0, 0, 0)
      if (offset > 0) {
        start.setTime(start.getTime() - 3600 * 1000 * 24 * offset)
      }
      if (offset === 1) {
        // 昨日：0点到23:59:59
        end.setHours(23, 59, 59, 999)
        end.setTime(end.getTime() - 3600 * 1000 * 24 * offset)
      }
      this.dateRange = [formatDate(start), formatDate(end)]
    },
    quickDate (forward) {
      // const start = new Date()
      if (this.dateRange.length > 1) {
        const endDate = new Date(this.dateRange[1])
        const startDate = new Date(this.dateRange[0])
        if (forward < 0) {
          startDate.setTime(startDate.getTime() - 3600 * 1000 * 24)
          endDate.setTime(startDate.getTime())
          endDate.setHours(23, 59, 59, 999)
        } else {
          endDate.setTime(endDate.getTime() + 3600 * 1000 * 24)
          startDate.setTime(endDate.getTime())
          startDate.setHours(0, 0, 0, 0)
        }
        this.dateRange = [formatDate(startDate), formatDate(endDate)]
      }
    },
    _query () {
      let params = {}
      // if (this.countryCode) {
      //   params.countryCode = this.countryCode
      // }
      // date range
      if (this.dateRange && this.dateRange.length > 1) {
        params.start = this.dateRange[0]
        params.end = this.dateRange[1]
      }
      // console.log(this.dateRange)
      // if (this.subChannel.length < 1) {
      //   if (this.primaryChannel.channelId) {
      //     params.channelId = this.primaryChannel.channelId
      //   }
      // } else {
      //   params.channelId = this.subChannel
      // }
      params.timeZone = this.timeZone
      if (JSON.stringify(params) === '{}') {
        return
      }
      this.$emit('search', params)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .panel {
    width: 100%;
    height: 90px;
    padding: 5px 0 15px;
    .channel, .action {
      height: 50%;
      width: 50%;
    }
    .item-title {
      width: auto;
      // height: 34px;
      line-height: 34px;
      text-align: center;
      margin-right: 5px;
      &::after {
        content: ':';
      }
    }
  }
</style>
