import * as types from './mutation-types'
// import VUE from 'vue'
const matutions = {
  [types.SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo
  },
  // [types.SET_COUNTRY_LIST](state, countryList) {
  //   state.countryList = countryList
  // }
}

export default matutions
