import axios from 'axios'
import router from '@/router'
// import { formatDate } from './index'
import { storage } from '@/storage'
import { Message, Loading } from 'element-ui'

const baseURL = process.env.NODE_ENV === 'development' ? '/gofire' : 'https://api.meta.skrmeta.com'

export const uploadPhotoUrl = baseURL + '/upload/photo'
export const uploadVideoUrl = baseURL + '/upload/video'
export const uploadAvatarUrl = baseURL + '/admin/avatar/upload' // 管理员头像上传接口
export const exportAwardUrl = baseURL + '/activity/award/export'


/**
 * 登录
 */
export function login (params) {
  return _request('/admin/login', 'post', undefined, params, true)
}
export function adminList (params) {
  return _request('/admin/list', 'get', undefined, params)
}
export function addOrUpdateAdmin (data) {
  return _request('/admin/addOrUpdate', 'post', data)
}
export function operateAdmin (params) {
  return _request('/admin/forbid', 'post', undefined, params)
}
export function deleteAdmin (params) {
  return _request('/admin/delete', 'put', undefined, params)
}
/**
 * 
 * 角色列表
 */
export function roleList (params) {
  return _request('/admin/role/list', 'get', undefined, params)
}
export function addOrUpdateRole (data) {
  return _request('/admin/role/addOrUpdate', 'post', data)
}
/**
 * 修改密码
 */
export function updatePwd (params) {
  return _request('/admin/password/update', 'put', undefined, params)
}
// 标签列表
export function labelList (params) {
  return _request('/label/list', 'get', undefined, params)
}
// 标签delete
export function deleteLabel (params) {
  return _request('/label/delete', 'delete', undefined, params)
}
// 标签添加或修改
export function addOrUpdateLabel (data) {
  return _request('/label/addOrUpdate', 'post', data)
}
export function genreList (params) {
  return _request('/label/category/list', 'get', undefined, params)
}
export function addOrUpdateGenre (data) {
  return _request('/label/category/addOrUpdate', 'post', data)
}
export function deleteGenre (params) {
  return _request('/label/category/delete', 'delete', undefined, params)
}
// 提现列表
export function withdrawList (params) {
  return _request('/withdraw/list', 'get', undefined, params)
}
// 提现审核
export function auditWithdraw (id, params) {
  return _request(`/withdraw/audit/${id}`, 'put', undefined, params)
}
// 平台列表【用于下拉选择框数据源】
export function allPlatform (params) {
  return _request('/platform/list/all', 'post', undefined, params)
}
export function platformList (params) {
  return _request('/platform/list', 'post', undefined, params)
}
export function addOrUpdatePlatform (data) {
  return _request('/platform/addOrUpdate', 'post', data)
}
export function operatePlatform (params) {
  return _request('/platform/cancel', 'post', undefined, params)
}
export function platformInfo (params) {
  return _request('/platform/queryByPid', 'post', undefined, params)
}
/**
 * 首页金币数据
 */
export function homeData (data) {
  return _request('/index/consume/sum', 'post', data, {})
}
/**
 * 充值记录
 */
export function chargeLog (params) {
  return _request('/index/distribute/log', 'post', undefined, params)
}
/**
 * 渠道列表(分页)
 */
export function channelList (params) {
  return _request('/channel/config/list', 'get', undefined, params)
}
/**
 * 新增或更新渠道
 */
export function addOrUpdateChannel (data) {
  return _request('/channel/config/addOrUpdate', 'post', data)
}
/**
 * App详情数据
 */
export function appDetailData (params) {
  return _request('/app/detail', 'post', undefined, params)
}
/**
 * App详情数据
 */
export function addOrUpdateAppDetail (data) {
  return _request('/app/change/detail', 'post', data)
}
/**
 * 价格配置列表
 */
export function configList (params) {
  return _request('/config/price/list', 'post', undefined, params)
}
/**
 * 添加或修改价格配置
 */
export function addOrUpdateConfig (data) {
  return _request('/config/price/addOrUpdate', 'post', data)
}
/**
 * 删除价格配置
 */
export function deleteConfig (params) {
  return _request('/config/price/delete', 'post', {}, params)
}

/**
 * 充值/分发记录列表
 */
export function distributeList (params) {
  return _request('/distribute/list', 'post', {}, params)
}
export function auditDistribute (params) {
  return _request('/distribute/audit', 'post', {}, params)
}
export function executeDistribute (params) {
  return _request('/distribute/execute', 'post', {}, params)
}
export function createDistribute (data) {
  return _request('/distribute/create', 'post', data)
}
/**
 * 兑换/分发记录列表
 */
export function redeemList (params) {
  return _request('/redeem/list', 'post', undefined, params)
}
/**
 * 属性
 */
export function attributeList (params) {
  return _request('/global/config/list', 'get', undefined, params)
}
export function updateAttribute (data) {
  return _request('/global/config/addOrUpdate', 'post', data)
}
// 金币充值配置列表
export function rechargeConfigList (params) {
  return _request('/coins/recharge/list', 'post', undefined, params)
}
// 启用或禁用金币配置
export function rechargeConfigOperate (params) {
  return _request('/coins/recharge/forbid', 'post', undefined, params)
}
// 新增或修改金币配置
export function addOrUpdateRechargeConfig (data) {
  return _request('/coins/recharge/addOrUpdate', 'post', data)
}
// 钻石充值配置列表
export function rechargeDiamondList (params) {
  return _request('/diamond/recharge/list', 'get', undefined, params)
}
// 新增或修改钻石配置
export function addOrUpdateRechargeDiamond (data) {
  return _request('/diamond/recharge/addOrUpdate', 'post', data)
}
// 目录列表【app tabbar入口】
export function catalogList (params) {
  return _request('/catalog/list', 'post', undefined, params)
}
// 目录包含的模块列表
export function catalogModuleList (params) {
  return _request('/catalog/module/list', 'post', undefined, params)
}
// 新增或修改catalog
export function addOrUpdateCatalog (data) {
  return _request('/catalog/addOrUpdate', 'post', data)
}
// 启用或隐藏分类
export function catalogOperate (params) {
  return _request('/catalog/show/status', 'post', undefined, params)
}
// 删除分类
export function deleteCatalog (params) {
  return _request('/catalog/delete', 'post', undefined, params)
}
// 更新分类的模块
export function updateCatalogModule (data) {
  return _request('/catalog/module/reset', 'post', data)
}
// 目录中对应的模块
export function moduleList (params) {
  return _request('/module/list', 'post', undefined, params)
}
// 目录中对应的模块
export function moduleOptionsList (params) {
  return _request('/module/usable/all', 'post', undefined, params)
}
// 新增或修改catalog
export function addOrUpdateModule (data) {
  return _request('/module/addOrUpdate', 'post', data)
}
// 模块下的应用列表
export function moduleAppList (params) {
  return _request('/module/app/list', 'post', undefined, params)
}
// 模块下新增或修改应用
export function updateModuleApp (data) {
  return _request('/module/app/reset', 'post', data)
}
// 删除分类
export function deleteModule (params) {
  return _request('/module/delete', 'post', undefined, params)
}
// app首页banner列表查询
export function appBannerList (params) {
  return _request('/app/index/banner/list', 'post', undefined, params)
}
// 首页banner维护
export function addOrUpdateBanner (data) {
  return _request('/app/index/banner/change', 'post', data)
}
// app首页banner显示状态修改
export function appBannerOperate (params) {
  return _request('/app/index/banner/show/change', 'post', undefined, params)
}
// app首页banner删除
export function appBannerRemove (params) {
  return _request('/app/index/banner/remove', 'post', undefined, params)
}
// app首页文章列表查询
export function appArticleList (params) {
  return _request('/app/index/info/list', 'post', undefined, params)
}
// 首页文章维护
export function addOrUpdateArticle (data) {
  return _request('/app/index/info/change', 'post', data)
}
// app首页文章显示状态修改
export function appArticleOperate (params) {
  return _request('/app/index/info/show/change', 'post', undefined, params)
}
// app首页文章删除
export function appArticleRemove (params) {
  return _request('/app/index/info/remove', 'post', undefined, params)
}
// app首页文章详情
export function articleDetailList (params) {
  return _request('/app/index/detail/list', 'post', undefined, params)
}
// 所有文章列表
export function articleOptionList (params) {
  return _request('/app/index/info/all/list', 'post', undefined, params)
}
// 首页文章详情维护
export function addOrUpdateArticleDetail (data) {
  return _request('/app/index/detail/change', 'post', data)
}
// app文章详情段落删除
export function articleDetailRemove (params) {
  return _request('/app/index/detail/remove', 'post', undefined, params)
}
// app推荐游戏列表
export function recommendGameList (params) {
  return _request('/game/recommend/info', 'post', undefined, params)
}
// 文章排序
export function sortRecommendGame (data) {
  return _request('/game/recommend/replace', 'post', data)
}
// 可用游戏列表查询
export function gameOptionsList (params) {
  return _request('/game/recommend/list', 'post', undefined, params)
}
// 新增或修改
export function addOrUpdateGame (params) {
  return _request('/game/recommend/change', 'post', undefined, params)
}
// 删除推荐位的游戏
export function recommendGameRemove (params) {
  return _request('/game/recommend/remove', 'post', undefined, params)
}
// live推荐用户列表
export function recommendLiveUserList (params) {
  return _request('/live/user/recommend/list', 'post', undefined, params)
}
// 新增或修改推荐位
export function recommendLiveUserConfig (params) {
  return _request('/live/user/recommend/config', 'post', undefined, params)
}
// 删除推荐位
export function recommendLiveUserRemove (params) {
  return _request('/live/user/recommend/delete', 'post', undefined, params)
}
// live用户列表
export function liveUserOptionList (params) {
  return _request('/account/subInfo/list', 'post', undefined, params)
}
// live banner
export function partyBannerList (params) {
  return _request('/party/banner/all/list', 'get', undefined, params)
}
// live banner
export function partySceneOptions (params) {
  return _request('/roomScene/admin/sub/list', 'get', undefined, params)
}
// live banner维护
export function addOrUpdatePartyBanner (data) {
  return _postFile('/party/banner/addOrUpdate', data)
}
// 删除live banner
export function partyBannerRemove (params) {
  return _request('/party/banner/delete', 'delete', undefined, params)
}
// 管理员创建房间
export function partyCreate (data) {
  return _request('/party/create', 'post', data)
}
// 管理员创建房间
export function partyCategory (params) {
  return _request('/room/group/admin/list', 'get', undefined, params)
}
// 分类维护
export function addOrUpdatePartyCategory (data) {
  return _postFile('/room/group/admin/change', data)
}
// 分类delete
export function deletePartyCategory (params) {
  return _request('/room/group/admin/delete', 'delete', undefined, params)
}
// 外部平台
/**
 * 首页金币消耗数据
 */
export function indexData (data) {
  return _request('/external/index/consume/sum', 'post', data, {})
}
export function platformData () {
  return _request('/external/index/platform/info', 'post')
}
export function platformPriceList (params) {
  return _request('/external/config/price/list', 'post', undefined, params)
}
export function platformDistributeList (params) {
  return _request('/external/distribute/list', 'post', undefined, params)
}
export function platformCreateDistribute (data) {
  return _request('/external/distribute/create', 'post', data)
}
export function platformRedeemList (data) {
  return _request('/external/redeem/list', 'post', data)
}
/**
 * 
 * @description 环信IM系统账号信息 
 */
export function imAccountList (data) {
  return _request('/system/push/account/list', 'post', data)
}
/**
 * 
 * @description 全站推送 
 */
export function sendSystemPush (params) {
  return _request('/system/push/send', 'post', undefined, params)
}
/**
 * 
 * @description 推送列表 
 */
export function pushList (params) {
  return _request('/system/push/list', 'get', undefined, params)
}
export function addOrUpdatePush (data) {
  return _postFile('/system/push/addOrUpdate', data)
}
/**
 * 
 * @description 审核开关列表信息
 */
export function auditList () {
  return _request('/audit/switch/list', 'get')
}
/**
 * 
 * @description 审核开关配置 
 * @param auditType
 * @param machineSwitch
 * @param personSwitch
 */
export function auditSwitch (params) {
  return _request('/audit/switch/update', 'post', undefined, params)
}
/**
 * 
 * @param account
 * @param avatar
 * @param nickname
 */
export function updateImAccount (params) {
  return _request('/system/push/account/update', 'post', undefined, params)
}
/**
 * 
 * @description 礼物列表信息
 */
export function giftList (params) {
  return _request('/gift/list', 'get', undefined, params)
}
/**
 * 
 * @description 添加或修改礼物
 */
export function addOrUpdateGift (data) {
  return _request('/gift/addOrUpdate', 'post', data)
}
/**
 * 
 * @description 用户列表信息
 */
export function userList (params) {
  return _request('/user/data/list', 'get', undefined, params)
}
/**
 * 
 * @description 修改用户状态
 */
export function updateUser (data) {
  return _request('/user/data/update', 'put', data)
}
/**
 * 
 * @description 语音房列表信息
 */
export function roomList (params) {
  return _request('/room/admin/list', 'get', undefined, params)
}
/**
 * 
 * @description 房间场景列表信息
 */
export function sceneList (params) {
  return _request('/roomScene/admin/list', 'get', undefined, params)
}
/**
 * 
 * @description 添加或修改场景
 */
 export function addOrUpdateScene (data) {
  return _postFile('/roomScene/admin/addOrUpdate', data)
}
/**
 * 
 * @description 关闭语音房
 */
export function roomClose (params) {
  return _request(`/room/admin/close`, 'post', undefined, params)
}
export function roomSort (params) {
  return _request(`/room/admin/sort/set`, 'post', undefined, params)
}
/// 房间人数配置
export function roomSizeConfigList () {
  return _request(`/roomScene/admin/userNum/query`, 'get')
} 
export function updateRoomSizeConfig (data) {
  return _request(`/roomScene/admin/userNum/config`, 'post', data)
}
/**
 * 
 * @description 换装商品列表信息
 */
export function dressUpItemList (params) {
  return _request('/goods/admin/list', 'get', undefined, params)
}
/**
 * 
 * @description 添加或修改换装商品
 */
export function addOrUpdateItem (data) {
  return _postFile('/goods/admin/addOrUpdate', data)
}
export function deleteDressUpItem (params) {
  return _request('/goods/admin/delete', 'delete', undefined, params)
}
/**
 * 
 * @description 动态审核列表信息
 */
export function postAuditList (params) {
  return _request('/audit/dynamic/list', 'get', undefined, params)
}
/**
 * 
 * @description 重新审核动态
 */
export function reAuditPost (params) {
  return _request('/audit/dynamic/redo', 'post', undefined, params)
}
/**
 * 
 * @description 动态审核数据统计
 */
export function postAuditData (params) {
  return _request('/audit/dynamic/total', 'get', undefined, params)
}
/**
 * 
 * @description 主题审核列表信息
 */
export function topicAuditList (params) {
  return _request('/audit/theme/list', 'get', undefined, params)
}
/**
 * 
 * @description 举报审核数据统计
 */
export function reportAuditData (params) {
  return _request('/audit/report/total', 'get', undefined, params)
}
/**
 * 
 * @description 举报审核列表信息
 */
export function reportAuditList (params) {
  return _request('/audit/report/list', 'get', undefined, params)
}
/**
 * 
 * @description 举报审核操作
 */
export function handleReport (params) {
  return _request('/audit/report/handle', 'post', undefined, params)
}
/**
 * 
 * @description 反馈审核数据统计
 */
export function feedbackAuditData (params) {
  return _request('/audit/feedback/total', 'get', undefined, params)
}
/**
 * 
 * @description 反馈审核列表信息
 */
export function feedbackAuditList (params) {
  return _request('/audit/feedback/list', 'get', undefined, params)
}
/**
 * 
 * @description 反馈审核操作
 */
export function handleFeedback (params) {
  return _request('/audit/feedback/handle', 'post', undefined, params)
}
/**
 * 
 * @description 重新审核主题
 */
export function reAuditTopic (params) {
  return _request('/audit/theme/redo', 'post', undefined, params)
}
/**
 * 
 * @description 主题审核数据统计
 */
export function topicAuditData (params) {
  return _request('/audit/theme/total', 'get', undefined, params)
}
/**
 * 
 * @description 主题分类列表信息
 */
export function topicCatalogList (params) {
  return _request('/category/admin/list/all', 'get', undefined, params)
}
/**
 * 
 * @description 主题分类删除
 */
export function deleteTopicCatalog (params) {
  return _request('/category/admin/delete', 'delete', undefined, params)
}
/**
 * 
 * @description 主题分类添加或修改
 */
export function addOrUpdateTopicCatalog (data) {
  return _request('/category/admin/change', 'post', data)
}
/**
 * 
 * @description 主题列表信息
 */
export function topicList (params) {
  return _request('/theme/admin/list', 'get', undefined, params)
}
/**
 * 
 * @description 主题更新
 */
export function updateTopic (data) {
  return _request('/theme/admin/update', 'put', data)
}
/**
 * 
 * @description 主题更新
 */
export function deleteTopic (params) {
  return _request('/theme/admin/delete', 'delete', undefined, params)
}
/**
 * 
 * @description 动态列表信息
 */
export function postList (params) {
  return _request('/dynamic/admin/list', 'get', undefined, params)
}
/**
 * 
 * @description 更新动态状态
 */
export function updatePost (data) {
  return _request('/dynamic/admin/update', 'put', data)
}
/**
 * 
 * @description 背景列表
 */
export function backgroundList (params) {
  return _request('/room/background/list', 'get', undefined, params)
}
/**
 * 
 * @description 删除背景
 */
export function deleteBackground (id) {
  return _request(`/room/background/delete/${id}`, 'delete', undefined)
}
/**
 * 
 * @description 添加或修改背景
 */
export function addOrUpdateBackground (data) {
  return _request('/room/background/addOrUpdate', 'post', data)
}
/**
 * 
 * @description 钻石赠送列表信息
 */
export function presentList (params) {
  return _request('/diamond/give/list', 'get', undefined, params)
}
/**
 * 
 * @description 赠送钻石
 */
export function presentDiamond (data) {
  return _request('/diamond/give', 'post', data)
}
/**
 * 修改密码
 */
export function platformUpdatePwd (params) {
  return _request('/external/platform/password/update', 'post', undefined, params)
}
/**
 * 
 * @description 钻石充值列表信息
 */
export function orderList (params) {
  return _request('/diamond/order/list', 'get', undefined, params)
}
/**
 * 
 * @description 钻石订单数据统计
 */
export function orderStats (params) {
  return _request('/diamond/order/total', 'get', undefined, params)
}
/**
 * 
 * @description 补单
 */
export function fixOrder (data) {
  return _request('/diamond/order/supply', 'put', data)
}

/**
 * 
 * @description 用户数据统计
 */
export function userStats (params) {
  return _request('/account/reg/data', 'get', undefined, params)
}

/**
 * 
 * @description 钻石消耗数据统计
 */
export function diamondStats (params) {
  return _request('/diamond/consume/total', 'get', undefined, params)
}

/**
 * 
 * @description 钻石消耗列表信息
 */
export function diamondConsumeList (params) {
  return _request('/diamond/consume/list', 'get', undefined, params)
}

/**
 * 
 * @description 首页用户/充值数据统计
 */
export function overallStats (params) {
  return _request('/user/data/total', 'get', undefined, params)
}
/**
 * 
 * @description 首页充值数据统计
 */
export function rechargeStats (params) {
  return _request('/index/data/recharge', 'get', undefined, params)
}
/**
 * 
 * @description 崩溃日志
 */
export function appCrashList (params) {
  return _request('/app/crash/list', 'post', undefined, params)
}
/**
 * 
 * @description 处理崩溃日志
 */
export function handleAppCrash (params) {
  return _request('/app/crash/solve', 'post', undefined, params)
}
/**
 * 
 * @description 运营活动列表
 */
export function activityList (params) {
  return _request('/activity/list', 'get', undefined, params)
}
/**
 * 
 * @description 添加或更新运营活动
 */
export function addOrUpdateActivity (data) {
  return _request('/activity/addOrUpdate', 'post', data)
}
/**
 * activityId usable
 * @description 开启或关闭运营活动
 */
export function activityOperate (params) {
  return _request('/activity/usable/set', 'post', undefined, params)
}
/**
 *
 * @description 活动奖励列表
 */
export function activityRewardList (params) {
  return _request('/activity/award/list', 'get', undefined, params)
}
/**
 * 
 * @description 活动商品列表
 */
export function activityProductList (params) {
  return _request('/activity/product/list', 'get', undefined, params)
}
/**
 * 
 * @description 活动商品列表
 */
export function activityProductOptions (params) {
  return _request('/activity/product/sub/list', 'get', undefined, params)
}
/**
 * 
 * @description 活动商品添加或更新
 */
export function addOrUpdateActivityProduct (data) {
  return _request('/activity/product/addOrUpdate', 'post', data)
}
/**
 * 
 * @description 根据分类查询主题列表
 */
export function topicListWithCatalog (params) {
  return _request('/theme/list/all', 'post', undefined, params)
}
/**
 * 
 * @description 所有头像框列表
 */
export function avatarFrameList (params) {
  return _request('/activity/resource/avatarFrame/list', 'post', undefined, params)
}
/**
 * 
 * @description 资源下载价格列表
 */
export function downloadSettings () {
  return _request('/config/download/get', 'post', undefined)
}
/**
 * 
 * @description 资源下载价格列表
 */
export function setDownloadInfo (data) {
  return _request('/config/download/set', 'post', data)
}
/**
 * 
 * @description 热搜列表
 */
export function searchList () {
  return _request('/search/rank/list', 'get')
}
/**
 * 
 * @description 热搜配置更新
 */
export function addOrUpdateSearch (data) {
  return _request('/search/rank/addOrUpdate', 'post', data)
}
/**
 * 
 * @description 热搜配置删除
 */
export function deleteSearch (params) {
  return _request('/search/rank/delete', 'delete', undefined, params)
}
/**
 * 
 * @description 举报理由列表
 */
export function reasonList () {
  return _request('/reason/admin/list', 'get')
}
/**
 * 
 * @description 举报理由更新
 */
export function addOrUpdateReason (data) {
  return _request('/reason/admin/addOrUpdate', 'post', data)
}
/**
 * 
 * @description 举报理由删除
 */
export function deleteReason (params) {
  return _request('/reason/admin/delete', 'delete', undefined, params)
}
/**
 * 用户操作记录 
 */
export function userRecords (params) {
  return _request('/user/operation/list', 'get', undefined, params)
}
/**
 * 用户违规记录 
 */
export function violationRecords (params) {
  return _request('/user/violation/list', 'get', undefined, params)
}
/**
 * 认证列表 
 */
export function certificationList (params) {
  return _request('/official/identify/list', 'get', undefined, params)
}
/**
 * 认证列表 
 */
export function auditCertification (params) {
  return _request('/official/identify/audit', 'post', undefined, params)
}
/**
 * 删除官方认证
 * @param {id} params 
 * @returns 
 */
export function deleteCertification (params) {
  return _request('/official/identify/cancel', 'delete', undefined, params)
}
/**
 * 手动认证-寿东村活动
 * @param userId
 * @returns 
 */
export function passCertification (params) {
  return _request('/official/identify/pass', 'post', undefined, params)
}
/**
 * 认证下拉列表
 * @param {*} params 
 * @returns 
 */
export function certificateOptionList (params) {
  return _request('/official/identify/sub/list', 'get', undefined, params)
}
/**
 * 寿东村资源配置 
 */
export function sceneConfigList (params) {
  return _request('/scene/config/admin/list', 'get', undefined, params)
}
export function sceneConfigUserList (params) {
  return _request('/scene/config/admin/user/list', 'get', undefined, params)
}
// 展位数量
export function sceneConfigCount (params) {
  return _request('/scene/config/admin/count', 'get', undefined, params)
}
export function initSceneConfig (data) {
  return _request(`/scene/config/admin/init`, 'post', data, undefined)
}
export function addSceneUserConfig (params) {
  return _request(`/scene/config/admin/user/add`, 'post', undefined, params)
}
export function addSceneConfig (data, idx, scene) {
  return _postFile(`/scene/config/admin/add?index=${idx}&type=${scene}`, data)
}
function _postFile(url, data) {
  return _request(url, 'post', data, undefined, false, true)
}

export function _request(url, methods, data = undefined, params = {}, auth = false, uploadFile = false) {
  let loadingInstance
  loadingInstance = Loading.service({ text: '加载中' })
  // console.log('data:', data)
  // 默认时间是当日，统一在接口中添加时间参数
  // if(needTime && data && !data.startTime) {
  //   const end = new Date()
  //   const start = new Date()
  //   start.setHours(0, 0, 0, 0)
  //   // 昨日：0点到23:59:59
  //   // end.setHours(23, 59, 59, 999)
  //   data.startTime = formatDate(start)
  //   data.endTime = formatDate(end)
  // }
  const configHeaders = { 
    authority: 'manager',
    interId: 1,
    Authorization: storage.get('token') || ''
  }
  if (auth) {
    configHeaders.Authorization = ''
  }
  if (uploadFile) {
    configHeaders['Content-Type'] = 'multipart/form-data'
  }
  const queryParams = {}
  for (const key in params) {
    const e = params[key]
    if (e !== '') queryParams[key] = e
  }
  let postData = uploadFile ? data : { ...data, interId: 1 }
  return new Promise((resolve, reject) => {
    axios({
      baseURL: baseURL,
      method: methods,
      url: url,
      headers: configHeaders,
      params: { ...queryParams, interId: 1 },
      data: postData,
    }).then((response) => {
      loadingInstance.close()
      // console.log('response=====>', response.data)
      const message = response.data.message || response.data.msg || '系统出错了，请重试！'
      const statusCode = response.data.code || response.data.status
      if (statusCode !== 200) {
        Message.error(message)
        if (statusCode === 401 || statusCode === 1002) {
          storage.clear()
          router.push('/login').catch(err => err)
        }
      } else {
        // if (message !== '') {
        //   Message.success(message)
        // }
        return resolve(response.data)
      }
    }).catch((error) => {
      // console.log('----catch:::error:', error.response)
      loadingInstance.close()
      let errorMsg = '系统出错了，请重试！'
      if (error && error.response && error.response.status === 401) {
        errorMsg = '会话已过期，请重新登录!'
      }
      Message.error(errorMsg)
      return reject(error)
    })
  })
}

axios.interceptors.response.use(
  response => {
    // console.log('----interceptors:::response:', response)
    return response
  },
  error => {
    console.log('--interceptors--error', error.response)
    if (error && error.response && error.response.status === 401) {
      storage.clear()
      router.push('/login').catch(err => err)
    }
    return Promise.reject(error)
  }
)
