import * as types from './mutation-types'
import { storage } from '@/storage'

export const dispatchUser = function({commit}) {
  let promise = new Promise(function (resolve) {
    const data = storage.get('user')
    commit(types.SET_USER_INFO, data)
    resolve()
  })
  return promise
}
