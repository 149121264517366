// for home index
const routes = [
  {
    path: '/home',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/index',
        component: () => import('../views/Index.vue'),
        meta: {
          role: 1,
          title: '首页',
          groupId: 1000
        }
      },
      {
        path: '/content/topic',
        component: () => import('../views/content/Topic.vue'),
        meta: {
          role: 1,
          title: '主题管理',
          groupId: 5200
        }
      },
      {
        path: '/content/post',
        component: () => import('../views/content/Post.vue'),
        meta: {
          role: 1,
          title: '动态管理',
          groupId: 5200
        }
      },
      {
        path: '/content/label',
        component: () => import('../views/content/Label.vue'),
        meta: {
          role: 1,
          title: '标签管理',
          groupId: 5200
        }
      },
      {
        path: '/live/banner',
        component: () => import('../views/live/Banner.vue'),
        meta: {
          role: 1,
          title: 'Banner管理',
          groupId: 7500
        }
      },
      {
        path: '/certify',
        component: () => import('../views/operate/Certificate.vue'),
        meta: {
          role: [1, 2],
          title: '认证管理',
          groupId: 7500
        }
      },
      {
        path: '/village/scene',
        component: () => import('../views/external/Scene.vue'),
        meta: {
          role: [1, 2, 3],
          title: '场景管理',
          groupId: 7500
        }
      },
      {
        path: '/village/user',
        component: () => import('../views/external/Premium.vue'),
        meta: {
          role: [1, 2, 3],
          title: '用户配置',
          groupId: 7500
        }
      },
      {
        path: '/live/party',
        component: () => import('../views/live/Room.vue'),
        meta: {
          role: [1, 3],
          title: '房间管理',
          groupId: 5300
        }
      },
      {
        path: '/live/party/scene',
        component: () => import('../views/live/Scene.vue'),
        meta: {
          role: 1,
          title: '场景管理',
          groupId: 5300
        }
      },
      {
        path: '/live/party/category',
        component: () => import('../views/live/Category.vue'),
        meta: {
          role: 1,
          title: '分类管理',
          groupId: 5300
        }
      },
      {
        path: '/channel/list',
        component: () => import('../views/channel/Channel.vue'),
        meta: {
          role: 1,
          title: '渠道管理',
          groupId: 8500
        }
      },
      {
        path: '/product/item',
        component: () => import('../views/product/Product.vue'),
        meta: {
          role: 1,
          title: '商品管理',
          groupId: 5000
        }
      },
      {
        path: '/users',
        component: () => import('../views/users/User.vue'),
        meta: {
          role: 1,
          title: '用户管理',
          groupId: 3000
        }
      },
      {
        path: '/users/record',
        component: () => import('../views/users/Record.vue'),
        meta: {
          role: 1,
          title: '操作记录',
          groupId: 3000
        }
      },
      {
        path: '/users/violation',
        component: () => import('../views/users/Violation.vue'),
        meta: {
          role: 1,
          title: '违规记录',
          groupId: 3000
        }
      },
      {
        path: '/settings',
        component: () => import('../views/setting/Settings.vue'),
        meta: {
          role: 1,
          title: '账户管理',
          groupId: 6000
        }
      },
      {
        path: '/push',
        component: () => import('../views/setting/Push.vue'),
        meta: {
          role: 1,
          title: '推送管理',
          groupId: 6000
        }
      },
      {
        path: '/search/favorites',
        component: () => import('../views/content/Favorites.vue'),
        meta: {
          role: 1,
          title: '热搜管理',
          groupId: 5200
        }
      },
      {
        path: '/content/reason',
        component: () => import('../views/content/Reasons.vue'),
        meta: {
          role: 1,
          title: '举报理由管理',
          groupId: 5200
        }
      },
      {
        path: '/audit/topic',
        component: () => import('../views/audit/Topic.vue'),
        meta: {
          role: 1,
          title: '主题审核',
          groupId: 5400
        }
      },
      {
        path: '/audit/post',
        component: () => import('../views/audit/Post.vue'),
        meta: {
          role: 1,
          title: '动态审核',
          groupId: 5400
        }
      },
      // {
      //   path: '/policy',
      //   component: () => import('../views/audit/Policy.vue'),
      //   meta: {
      //     role: 1,
      //     title: '审核设置',
      //     groupId: 5400
      //   }
      // },
      // {
      //   path: '/audit/withdraw',
      //   component: () => import('../views/audit/Withdraw.vue'),
      //   meta: {
      //     role: 1,
      //     title: '提现审核',
      //     groupId: 5400
      //   }
      // },
      {
        path: '/audit/feedback',
        component: () => import('../views/audit/Feedback.vue'),
        meta: {
          role: 1,
          title: '反馈审核',
          groupId: 5400
        }
      },
      {
        path: '/report',
        component: () => import('../views/audit/Report.vue'),
        meta: {
          role: 1,
          title: '举报审核',
          groupId: 5400
        }
      },
      {
        path: '/attribute',
        component: () => import('../views/Attribute.vue'),
        meta: {
          role: 1,
          title: '属性管理',
          groupId: 6500
        }
      },
      // {
      //   path: '/stats/orders',
      //   component: () => import('../views/stats/Order.vue'),
      //   meta: {
      //     role: 1,
      //     title: '订单管理',
      //     groupId: 5500
      //   }
      // },
      // {
      //   path: '/stats/users',
      //   component: () => import('../views/stats/User.vue'),
      //   meta: {
      //     role: 1,
      //     title: '用户统计',
      //     groupId: 5500
      //   }
      // },
      // {
      //   path: '/stats/diamonds',
      //   component: () => import('../views/stats/Diamond.vue'),
      //   meta: {
      //     role: 1,
      //     title: '兑换统计',
      //     groupId: 5500
      //   }
      // },
      // {
      //   path: '/app/log',
      //   component: () => import('../views/Log.vue'),
      //   meta: {
      //     role: 1,
      //     title: 'app崩溃日志',
      //     groupId: 7000
      //   }
      // },
      // {
      //   path: '/operate/activity',
      //   component: () => import('../views/operate/Activity.vue'),
      //   meta: {
      //     role: 1,
      //     title: '活动管理',
      //     groupId: 7500
      //   }
      // },
      // {
      //   path: '/operate/activity/reward',
      //   component: () => import('../views/operate/Reward.vue'),
      //   meta: {
      //     role: 1,
      //     title: '奖励管理',
      //     groupId: 7500
      //   }
      // },
      // {
      //   path: '/operate/activity/product',
      //   component: () => import('../views/operate/Product.vue'),
      //   meta: {
      //     role: 1,
      //     title: '商品管理',
      //     groupId: 7500
      //   }
      // },
    ]
  },
]

export default routes
