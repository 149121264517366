import routes from '../router/routes'

export default function registerRoutes (role, $router) {
  // 外部平台：2  管理员：1
  // role = role === 2 ? 2 : 1
  let item = routes[0]
  let allowedRoutes = [{
    path: item.path,
    component: item.component,
    children: []
  }]
  for (const child of item.children) {
    // if (child.meta.visible === 0) continue
    if (Array.isArray(child.meta.role)) {
      if (!child.meta.role.includes(role)) continue
    } else if (role !== child.meta.role) {
       continue
    }
    // if (role !== child.meta.role) continue
    allowedRoutes[0].children.push(child)
  }
  $router.addRoutes(allowedRoutes)
}
